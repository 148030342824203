import axios from 'axios'
import { Toast } from 'vant'
import store from '@/store'
import { getToken } from '@/utils/auth'
import router from '@/router'

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 30000,
})

// request interceptor
service.interceptors.request.use(
    config => {

        if (store.getters.token) {
            config.headers['Authorization'] = 'Bearer ' + getToken()
        }
        return config
    },
    error => {
        // do something with request error
        console.log('request err', error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
let messageAll;
service.interceptors.response.use(
    response => {
        const res = response.data

        if (messageAll) {
            messageAll.clear()
        }

        if (res.status_code == 500) {//token 无效失效
            store.dispatch('user/resetToken').then(() => {
                router.push({
                    path: '/',
                    // query: { redirect: router.currentRoute.fullPath }
                })
                messageAll = Toast('请重新连接钱包')
            })
            return Promise.reject(new Error(res.message || 'Error'))
        } else if (res.status_code == 0) {
            if (res.message && res.message != 'sucess') {
                messageAll = Toast(res.message);
            }
            return res.data
        } else {
            messageAll = Toast('请求失败')
            return Promise.reject(res)
            // return res
        }
    },
    error => {
        console.log('请求错误:' + error) // for debug
        Toast(error.message || '请求失败，请稍后再试')
        return Promise.reject(error)
    }
)

export default service
