const getters = {
  token: state => state.user.token,
  
  // web3
  // tronWeb: state => state.user.tronWeb,
  walletAddress: state => state.user.walletAddress,

  // info
  email: state => state.user.email,
  USDT_to_HongKong_price: state => state.user.USDT_to_HongKong_price,
  exchange_limit_min_number: state => state.user.exchange_limit_min_number,
  userInfo: state => state.user.userInfo,
  lavelList: state => state.user.lavelList,
}
export default getters
