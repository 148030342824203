<template>
  <div id="app">
    <router-view />

    <!-- 底部信息 -->
    <div class="bottomTc"></div>
    <div class="bottomTip">
      <div v-if="email">客服邮箱：{{email}}</div>
      <div>@2022瑞森港通</div>
    </div>
  </div>
</template>
<script>
  // import TronWeb from 'tronweb';
  import { mapGetters } from 'vuex'
  export default {
    computed: {
      ...mapGetters(['email', 'token'])
    },
    data() {
      return {
        // balance: '0',
        // tokenDecimals: 18, // 替换为你的TRC20代币的小数位数
      };
    },

    async created() {
      await this.connectTronLink();
    },

    methods: {
      async connectTronLink() {
        return new Promise((resolve, reject) => {
          const checkTronWeb = setInterval(() => {
            if (window.tronWeb && window.tronWeb.ready) {
              clearInterval(checkTronWeb);
              console.log('TronLink is installed.');
              // this.$store.commit('user/SET_TRON_WEB',window.tronWeb)

              // 获取已连接的钱包地址
              var walletAddress = window.tronWeb.defaultAddress.base58;
              this.$store.commit('user/SET_WALLET_ADDRESS', walletAddress)

              // 如果token存在则获取info和basics
              if (this.token) {
                this.$store.dispatch('user/getInfo')
                this.$store.dispatch('user/getBasics')
              }

              resolve()
            }
          }, 100); // 每100ms检查一次

        })
      },
    },
  };
</script>

<style lang="less">
  @import url('@/styles/common.less');

  #app {
    height: auto;
    min-height: 100%;
    position: relative;

    .bottomTc {
      height: 0.6rem;
    }

    .bottomTip {
      position: absolute;
      bottom: 0.17rem;
      left: 0;
      right: 0;
      font-size: 0.12rem;
      color: #DEDEE0;
      text-align: center;

      div+div {
        margin-top: 0.08rem;
      }
    }
  }
</style>