// 设置 rem 函数
function setRem() {
    // 基准大小
    let basePc = 100 / 375;//100 / 375
    let vW = document.documentElement.clientWidth || window.innerWidth; // 当前窗口的宽度
    var rem;
    if (vW >= 750) {
        rem = 750 * basePc;
    } else {
        rem = 375 * basePc; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应font-size值
    }
    document.documentElement.style.fontSize = rem + "px";
}
// 初始化
setRem();
// 改变窗口大小时重新设置 rem
window.onresize = function () {
    setRem()
};