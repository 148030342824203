import { loginFront, login, getInfo, getBasics } from '@/api/api'
import {
  getToken, setToken, removeToken,
} from '@/utils/auth'
import { Toast } from 'vant'

const state = {
  token: getToken(),

  // web3
  // tronWeb: null,
  walletAddress: '',
  // basics
  email: '',
  USDT_to_HongKong_price: '',
  exchange_limit_min_number:'',
  // info
  userInfo: {},
  lavelList: [
    '普通用户',//无效用户
    '有效用户',
    '初级代理',
    '中级代理',
    '高级代理',
  ],
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },

  // web3
  // SET_TRON_WEB: (state, tronWeb) => {
  //   state.tronWeb = tronWeb
  // },
  SET_WALLET_ADDRESS: (state, walletAddress) => {
    state.walletAddress = walletAddress
  },

  SET_BISICS: (state, data) => {
    state.email = data.service_email
    state.USDT_to_HongKong_price = data.USDT_to_HongKong_price
    state.exchange_limit_min_number = data.exchange_limit_min_number
  },
  SET_USER_INFO: (state, userInfo) => {
    userInfo.levelName = state.lavelList[userInfo.level];

    state.userInfo = userInfo;
  },
}

const actions = {
  // user login
  login({ state, commit }, parent_address) {
    return new Promise(async (resolve, reject) => {
      // 获取已连接的钱包地址
      var walletAddress = window.tronWeb.defaultAddress.base58;
      commit('SET_WALLET_ADDRESS', walletAddress)

      if (!state.walletAddress) {
        Toast('钱包获取失败！')
      } else {
        loginFront({
          address: state.walletAddress,
          parent_address
        }).then(async frontRes => {
          var hexCode = window.tronWeb.toHex(frontRes.code)

          var sign_data = await window.tronWeb.trx.sign(hexCode);
          var isValid = await window.tronWeb.trx.verifyMessage(hexCode, sign_data, state.walletAddress)

          if (isValid) {
            login({
              address: state.walletAddress,
              // sign_data
            }).then(res => {
              if (res) {
                commit('SET_TOKEN', res['token'])
                setToken(res['token'])

                resolve()
              } else {
                reject(res)
              }
            }).catch(error => {
              reject(error)
            })
          } else {
            console.log(888)
            reject('登录失败')
            Toast('登录失败')
          }

        }).catch(error => {
          reject(error)
        })
      }
    })
  },
  // get info
  getInfo({ commit }) {
    return new Promise(async (resolve, reject) => {
      getInfo().then(res => {
        // console.log(res)
        commit('SET_USER_INFO', res)

        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get basics
  getBasics({ commit }) {
    return new Promise(async (resolve, reject) => {
      getBasics().then(res => {
        // console.log(res)
        commit('SET_BISICS', res)

        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      removeToken()

      resolve()
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
