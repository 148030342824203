import request from '@/utils/request'

// --------- 基础 ---------
// 登录前置
export const loginFront = (params) => {
    return request({
        url: '/api/login_front',
        method: 'get',
        params
    })
}
// 登录
export const login = (data) => {
    return request({
        url: '/api/login',
        method: 'post',
        data
    })
}
// 获取用户信息
export const getInfo = (params) => {
    return request({
        url: '/api/get_user_info',
        method: 'get',
        params
    })
}
// 绑定手机
export const bindMobile = (data) => {
    return request({
        url: '/api/bind_mobile',
        method: 'post',
        data
    })
}
// 获取配置项信息
export const getBasics = (params) => {
    return request({
        url: '/api/basics',
        method: 'get',
        params
    })
}
// 港股兑换申请
export const exchangeGanggu = (data) => {
    return request({
        url: '/api/exchange_ganggu',
        method: 'post',
        data
    })
}

// 获取港股港权日志
export const getGanggugangquanLog = (params) => {
    return request({
        url: '/api/get_ganggugangquan_log',
        method: 'get',
        params
    })
}
// 获取港股日志
export const getExchangeList = (params) => {
    return request({
        url: '/api/get_exchange_list',
        method: 'get',
        params
    })
}

// 获取团队业绩
export const getTeamList = (params) => {
    return request({
        url: '/api/get_tuandui_yeji',
        method: 'get',
        params
    })
}

// 获取佣金列表
export const getRewardLog = (params) => {
    return request({
        url: '/api/get_reward_log',
        method: 'get',
        params
    })
}

// 获取公告列表
export const getNoticeList = (params) => {
    return request({
        url: '/api/notice/list',
        method: 'get',
        params
    })
}