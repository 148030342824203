import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from '@/utils/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {//剩余额度
    path: '/limit',
    name: 'limit',
    component: () => import('../views/limit.vue')
  },
  {//已兑换额度
    path: '/exchangeLimit',
    name: 'exchangeLimit',
    component: () => import('../views/exchangeLimit.vue')
  },
  {//团队
    path: '/team',
    name: 'team',
    component: () => import('../views/team.vue')
  },
  {//佣金
    path: '/commission',
    name: 'commission',
    component: () => import('../views/commission.vue')
  },

  {
    path: '/notification',
    name: 'notification',
    component: () => import('../views/notification.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 白名单允许，未登录访问
const whiteList = ['/']

router.beforeEach(async (to, from, next) => {
  if (!!getToken()) {
    next()
  } else {
    if (whiteList.indexOf(to.path) !== -1) {//是否在白名单中，允许未登录访问
      next()
    } else {
      // 未登录
      next({
        path: '/',
        // query: { redirect: to.path }
      });
    }
  }
})

export default router
