// const TokenKey = 'Admin-Token'

export function getToken() {
    return localStorage.getItem("token");
}

export function setToken(token) {
    try {
        localStorage.setItem('token', token)
    } catch (err) {
        // 处理空间不足的情况
        localStorage.removeItem("token");
        localStorage.setItem('token', token)
    };
}

export function removeToken() {
    return localStorage.removeItem("token");
}
